.navbar {
  justify-content: space-between;
  position: fixed;
  top: 0px;
  width: 100%;
}

.logo {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0px;
  margin: 0px;
  justify-content: center;
  text-align: center;
  display: flex; /* Add this property */
  align-items: center; /* Add this property */
}

.row {
  min-width: 100%;
}

.left-buttons {
  float: left;
  margin-left: 25px;
}

ul {
  padding-left: 2.5%;
  margin-bottom: 0px;
}

a {
  font-size: calc(12px + 1vmin);
  font-weight: 500;
  color: white !important;
  text-decoration: none;
}

a:hover {
  font-size: calc(12px + 1vmin);
  font-weight: 500;
  opacity: 0.7;
  text-decoration: none;
}

li {
  display: inline-block;
  position: relative;
  padding-top: 1%;
  margin-right: 12%;
}
li:last-child {
  margin-right: 0;
}

li:after {
  content: "";
  display: block;
  margin: auto;
  height: calc(2px + 0.1vmin);
  width: 0px;
  background: transparent;
  transition: width 0.3s ease, background-color 0.7s ease;
}
li:hover:after {
  width: 100%;
  background: #45a29e;
  color: lightgray;
}

.right-buttons {
  display: flex;
  flex-direction: row;
  float: right;
  margin-right: 20px;
}

.right-buttons a {
  margin: 0px 10px 0px 5px;
  padding: 0px 0px;
  background-color: transparent;
  border-color: transparent;
  height: calc(12px + 4vmin);
  width: calc(12px + 4vmin);
}

.right-buttons svg {
  height: calc(12px + 4vmin);
  width: calc(12px + 4vmin);
}

.right-buttons :hover {
  background-color: transparent;
  border-color: transparent;
}

.logo {
  height: calc(15px + 4vmin);
  width: calc(15px + 4vmin);
}
