#emailContainer {
  margin: -4% 0% 4% 0%;
  background-color: white;
  width: 100%;
  overflow: hidden;
  height: auto;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;

  @media (max-width: 700px) {
    width: 95%;
  }
}

#formContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#formRow {
  max-height: 80vh;
}

#underline {
  height: 1px;
  background-color: black;
  margin: 0% 0% 2% 0%;
}

#emailCol {
  max-height: 80vh;
  padding: 6%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#picCol {
  top: 0;
}

#contactTitle {
  text-align: left;
  font-weight: 800;
  font-size: xx-large;

  @media (min-width: 1500px) {
    font-weight: 800;
    font-size: 3rem;
  }

  @media (max-width: 700px) {
    font-weight: 800;
    font-size: x-large;
  }
}

#contactTitleMessage {
  text-align: left;
  padding: 3% 0 6% 0;
  font-size: large;
  font-weight: 500;

  @media (min-width: 1500px) {
    font-size: x-large;
    font-weight: 500;
  }

  @media (max-width: 700px) {
    font-weight: 500;
    font-size: medium;
  }
}

.emailForm {
  border-width: 2px;
  border-color: #f5f3f2;
  border-radius: 10px;
  background-color: #f5f3f2;
  color: black;
  display: flex;
  height: 100%;
  width: 100%;
  box-shadow: none;
}

.emailForm:focus {
  border-width: 2px;
  border-color: #f5f3f2 !important;
  border-radius: 10px;
  background-color: #f5f3f2 !important;
  color: black !important;
  display: flex;
  height: 100%;
  width: 100%;
  box-shadow: none;
}

#form {
  width: 100%;
}

.submitButton {
  background-color: black;
  color: white;
  margin: 10px 4px;
  border-radius: 10px;
  border-color: black;
  font-size: calc(10px + 1vmin);
  border-width: calc(1px + 0.3vmin);
  transition: none;
  width: 100%;
}

.submitButton:hover {
  background-color: #45a29e;
  border-color: #45a29e;
}
