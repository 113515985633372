.photoGallery {
  padding: 0.4% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoGallery {
  border-radius: 15px;
  z-index: 100;
}

.galleryContainer {
  padding-top: 10vh;
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #66FCF1;
  background-color: black; /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}
