.assistant {
  width: 100%;
  border-radius: 20px;
  background-color: #1A1A1A;
  padding: 3%;
  text-align: left;
  font-size: medium;

  @media (max-width: 768px) {
    font-size: small;
  }
}

.user {
  width: 100%;
  border-radius: 20px;
  padding: 3%;
  text-align: right;
  font-size: medium;

  @media (max-width: 768px) {
    font-size: small;
  }
}

.loading-dot {
  display: block;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;

  @media (max-width: 1200px) {
    width: 7px;
    height: 7px;
  }

  @media (max-width: 1000px) {
    width: 6px;
    height: 6px;
  }

  @media (max-width: 768px) {
    width: 5px;
    height: 5px;
  }
}