#bookCol {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 7% 2% 2%;
}

#bookText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookTitle {
  text-align: left;
  font-weight: 800;
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 20%;

  @media (min-width: 1500px) {
    font-weight: 800;
    font-size: 3rem;
  }

  @media (max-width: 700px) {
    font-weight: 800;
    font-size: x-large;
  }
}

.pageContent {
  height: 100vh;
  padding-top: 5%;
}

#chat {
  z-index: 100;
}