#bookColC {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 7% 5% 7%;

  @media (max-width: 700px) {
    padding: 2% 7% 5% 7%;
  }
}

#bookText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookTitle {
  text-align: left;
  font-weight: 800;
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 20%;

  @media (min-width: 1500px) {
    font-weight: 800;
    font-size: 3rem;
  }

  @media (max-width: 700px) {
    font-weight: 800;
    font-size: x-large;
  }
}

.pageContentC {
  height: 100vh;
  padding-top: 10vh;

  @media (max-width: 700px) {
    padding-top: 8vh;
  }
}

#bookContainer {
  z-index: 100;
}