.splashScreen {
  z-index: 0;
  background: black;
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dot {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background-color: white;
  border-radius: 50%;
}
