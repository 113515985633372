.experience {
  background-color: white;
  min-height: 100%;
  cursor: pointer;
}

.experienceCol {
  padding: 3%;
  max-height: 90vh;
  cursor: pointer;
}

.experienceBody {
  color: #1f2833;
  font-size: calc(11px + 0.2vmax);
  font-weight: 500;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#front {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordions {
  height: 90%;
}
