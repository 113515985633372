.newContainer {
  z-index: 5;
  padding: 0px;
  min-width: 100%;
  background: linear-gradient(
    to right,
    white 0%,
    white 45%,
    white 55%,
    white 100%
  );
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8vh;
  overflow-y: auto;

  @media (min-width: 700px) {
    height: 95%;
  }
}

.siteContent {
  z-index: 0;
  background: black;
  margin: 0px;
  padding: 0px;
  height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0rem;
}

.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentRow {
  height: 100%;

  @media (max-width: 800px) {
    height: 50%;
  }
}

.aboutRow {
  height: 100%;
}

#close {
  z-index: 100;
  position: fixed;
  top: 10px;
  right: 15px;
  color: white;
  border-color: white;
}
