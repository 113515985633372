.Stage1 {
  z-index: 0;
  background: linear-gradient(
    to bottom,
    black 0%,
    black 75%,
    white 75%,
    white 100%
  );
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-self: unset;
}

.firstScreen {
  height: 100vh;
}

.test {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#About {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#Photos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
}

#Contact {
  background-color: transparent;
  z-index: 1;
}

.row {
  --bs-gutter-x: 0rem;
}
