#photoContainer {
  width: 90%;
  z-index: 1;
  color: white;

  @media (max-width: 700px) {
    width: 85%;
  }
}

.photo {
  padding: 0.8% !important;
  border-radius: 15px;
}

.photoText {
  padding-top: 5%;
}

.photoTextItalCol {
  text-align: left;
  font-style: italic;
  font-weight: 600;
  font-size: 1.5rem;

  @media (max-width: 700px) {
    font-size: large;
  }
}

.photoTextCol {
  text-align: left;
  padding: 1% 0% 1% 0%;

  @media (max-width: 700px) {
    font-size: small;
    padding: 1% 0% 5% 0%;
  }
}

.photoTextTitleCol {
  text-align: left;
  padding-bottom: 5%;
  font-weight: 800;
  font-size: 2.4rem;

  @media (max-width: 700px) {
    font-weight: 800;
    font-size: xx-large;
  }
}

#text {
  background-color: #1f2833;
}
