.unc {
  background-color: #1f2833;
  min-height: 100%;
  cursor: pointer;
}

#uncBody {
  color: white;
  font-size: calc(11px + 0.5vmax);
  font-weight: 500;
}

.otherBody {
  color: #1f2833;
  font-size: calc(10px + 0.3vmax);
  font-weight: 500;
  height: 100%;
}

#uncImg {
  padding: 3% 0% 0% 0%;
}

.lu {
  padding: 1% 1% 1% 1%;
  min-height: 100%;
  cursor: pointer;
}

#luImg {
  padding: 5% 0% 0% 0%;
}

.ncssm {
  min-height: 100%;
  padding: 1% 1% 1% 1%;
  cursor: pointer;
}

#ncssmImg {
  padding: 2% 0% 3% 0%;
}

#topRow {
  height: 60%;
  padding: 1% 1% 2% 1%;
}

#bottomRow {
  height: 40%;
}

#front {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordions {
  height: 90%;
}
