.site-content {
  background-color: black;
  overflow: hidden;
  height: fit-content;
}

.firstScreen {
  height: 95vh;
}

.test {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

#b {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

#c {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

#d {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'NunitoSans';
  src: local('NunitoSans'), url(./fonts/NunitoSans/NunitoSans-VariableFont_YTLC\,opsz\,wdth\,wght.ttf) format('opentype');
}

@font-face {
  font-family: 'NunitoSans';
  src: local('NunitoSans'), url(./fonts/NunitoSans/NunitoSans-Italic-VariableFont_YTLC\,opsz\,wdth\,wght.ttf) format('opentype');
}