.OverlapOne {
  z-index: 100;
  color: white;
  width: 70vw;

  @media (max-width: 700px) {
    width: 85vw;
  }
}

.summary {
  font-size: calc(40px + 1vmin);
}

.tellMore {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 80vh;
  width: 100%;
  border-radius: 20px;
}

.tellButtonCol {
  display: flex;
  justify-content: center;
  align-content: center;
}

.chatContainer {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

.assistant {
  width: 100%;
  border-radius: 20px;
  background-color: #323333;
  text-align: left;

  @media (max-width: 700px) {
    padding: 5% !important;
  }
}

.user {
  width: 100%;
  border-radius: 20px;
  padding: 3%;
  text-align: right;

  @media (max-width: 700px) {
    padding: 5% !important;
  }
}

.chatMessages {
  display: flex;
  height: 90%;
  width: 100%;
}

.promptInput {
  display: flex;
  height: 10%;
  width: 100%;
  border-radius: 20px;
  justify-content: center;
  align-content: center;
  padding: 2%;
}

.promptInputCont {
  height: 100%;
}

.promptInputRow {
  height: 100%;
}

#promptSubmitBtn {
  padding: 5px;
}

.promptInputForm {
  border-width: 2px;
  border-color: white;
  border-radius: 20px;
  background-color: transparent;
  color: white;
  display: flex;
  height: 100%;
  width: 100%;
  box-shadow: none;
}

.form-control:focus {
  border-width: 2px;
  border-color: white;
  border-radius: 20px;
  background-color: transparent;
  color: white;
  display: flex;
  height: 100%;
  width: 100%;
  box-shadow: none;
}

.promptInputCol {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  min-height: 100%;
}

.row {
  display: flex;
  text-align: center;
}

.tellButton {
  background-color: transparent;
  margin: 10px 4px;
  border-radius: 20px;
  border-color: #45a29e;
  font-weight: 500;
  font-size: calc(10px + 1vmin);
  border-width: calc(1px + 0.3vmin);
  transition: none;
  justify-content: center;
  text-align: center;
  display: flex; /* Add this property */
  align-items: center; /* Add this property */
}

.a {
  background-color: transparent;
  margin: 10px 4px;
  border-radius: 0rem;
  border-color: #45a29e;
  font-size: calc(10px + 1vmin);
  border-width: calc(1px + 0.3vmin);
}

.bottomDiv {
  z-index: 5;
  position: absolute;
  top: 93%;
}
