.gridContainer {
  z-index: 1;
  width: 90%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.textCont {
  display: flex;
  padding: 5%;
}

#navGrid {
  margin: 7% 0 10% 0;

  @media (max-width: 700px) {
    margin: 10% 0 13% 0;
  }
}

.gridImg {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  padding: 5%;
}

.gridTextTitle {
  text-align: left !important;
  padding-bottom: 3%;
  font-weight: 800;
  font-size: xx-large;

  @media (max-width: 800px) {
    font-size: large;
  }
}

.gridText {
  text-align: left !important;
  color: white;

  @media (max-width: 800px) {
    font-size: small;
  }
}