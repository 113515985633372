.photoD {
  padding: 0.8% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoD {
  border-radius: 15px;
  z-index: 100;
}

.galleryContainer {
  padding-top: 10vh;
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}

.dot {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background-color: white;
  border-radius: 50%;
}